export interface ConfigData {
	[key: string]: any;
}

export var AlertsConfigData: ConfigData = {};

AlertsConfigData.error = {
	icon: 'error'
};

AlertsConfigData.success = {
	icon: 'done'
};

AlertsConfigData.DefaultSettings = {
	icon: 'error',
	timeout: 3
};