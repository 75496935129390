import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { AlertsService } from './alerts.service';

export * from './alerts.component';
export * from './alerts.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AlertsComponent
  ],
  exports: [
    AlertsComponent
  ]
})
export class AlertsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AlertsModule,
      providers: [AlertsService]
    };
  }
}
