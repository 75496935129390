import { Component, OnInit } from '@angular/core';
import { AlertsService } from './alerts.service';



export interface Message {
  message: string;
  type: string;
  [key: string]: string;
}

@Component({
  selector: 'app-alerts',
  template: `<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div  class="alertsContainer">
	<div class="alertsRow {{message.type}}" *ngFor="let message of messages">
		<div class="iconpanel"><i class="mat-icon material-icons">{{message.icon}}</i></div>
		<div class="messagepanel">{{message.message}}</div>
		<div class="closeicon"><a (click)="removeMessage(message)" href="#">X</a></div>
  	</div>
</div>`,
  styles: [`.alertsContainer{position:absolute;top:10px;right:20px}.alertsContainer .material-icons{padding-top:36%}.alertsContainer .alertsRow{margin-top:12px;transition:box-shadow 280ms cubic-bezier(.4,0,.2,1);display:block;position:relative;box-shadow:0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);border-radius:2px;transition:3s opacity 0;float:left;clear:both}.alertsContainer .alertsRow .iconpanel{float:left;width:50px;padding:15px;text-align:center;height:51px}.alertsContainer .alertsRow .messagepanel{float:left;height:41px;padding:20px;text-align:left;max-width:353px;width:350px}.alertsContainer .alertsRow .closeicon{position:absolute;top:10px;right:10px;display:none;text-decoration:none}.alertsContainer .alertsRow:hover .closeicon{display:block}.alertsContainer .alertsRow.error{border:1px solid #d80202}.alertsContainer .alertsRow.error .iconpanel{background:#d80202}.alertsContainer .alertsRow.error .messagepanel{color:#000;background:#fff}.alertsContainer .alertsRow.error .closeicon{color:#000}.alertsContainer .alertsRow.error .closeicon a{color:#000;text-decoration:none;font-size:12px}.alertsContainer .alertsRow.success{border:1px solid #27b235}.alertsContainer .alertsRow.success .iconpanel{background:#27b235;color:#fff}.alertsContainer .alertsRow.success .messagepanel{color:#000;background:#fff}.alertsContainer .alertsRow.success .closeicon{color:#000}.alertsContainer .alertsRow.success .closeicon a{color:#000;text-decoration:none;font-size:12px}.alertsContainer .alertsRow.warn{border:1px solid #ffc549}.alertsContainer .alertsRow.warn .iconpanel{background:#ffc549;color:#fff}.alertsContainer .alertsRow.warn .messagepanel{color:#000;background:#fff}.alertsContainer .alertsRow.warn .closeicon{color:#000}.alertsContainer .alertsRow.warn .closeicon a{color:#000;text-decoration:none;font-size:12px}`]
})
export class AlertsComponent implements OnInit {
  public messages: Object[] = [];
  public timeout: any;
  constructor(private alerts: AlertsService) { 

  }

  ngOnInit() {
    this.alerts.getSubject().subscribe( (object: Message) => {
        object.icon = this.alerts.getConfig(object.type,'icon');
        this.timeout = this.alerts.getConfig(object.type,'timeout');    
        console.log('this.timeout',this.timeout);
        this.messages.push(object);
        if(this.timeout > 0) {
          setTimeout( () => {
            this.removeMessage(object);
          },this.timeout * 1000);
        }
      });    
  }

  removeMessage(message: Object) {
      this.messages.splice(this.messages.indexOf(message),1);
  }

}