import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { Observable }    from 'rxjs';
import { AlertsConfigData } from './alerts.config';


export interface AlertsInterface {
  alertsSubject:Subject<Object>;
  setMessage(message: string, type: string): void;
  getConfig(message: string, key: string): string;
  setConfig(messagetype: string, key: string, value: string): void;
}
 
@Injectable()
export class AlertsService implements AlertsInterface    {
 
  // Observable alerts
  alertsSubject = new Subject<Object>();

  constructor() {
  }

  // Service message commands
  setMessage(message: string, type: string) {
    this.alertsSubject.next({message:message,type:type});
  }

  getSubject(): any {
    return this.alertsSubject.asObservable();
  }

  getConfig(messagetype: string, key: string) : string {
    if(typeof AlertsConfigData[messagetype] == 'undefined') {
        return AlertsConfigData.DefaultSettings[key];
    } else {
        return (typeof AlertsConfigData[messagetype][key] != 'undefined') ? AlertsConfigData[messagetype][key]: AlertsConfigData.DefaultSettings[key];
    }      
  }

  setConfig(messagetype: string, key: string, value: string) {
    if(typeof AlertsConfigData[messagetype] == 'undefined') {
        AlertsConfigData[messagetype] = {};
    }
    AlertsConfigData[messagetype][key] = value;
  }

  setDefaults(key: string, value: any) {
    AlertsConfigData.DefaultSettings[key] = value;    
  }

}